import { resolvable }        from '@mathquis/modelx-resolvables';
import PlanImportStatusModel from 'Models/time/PlanImportStatusModel';
import PlanSourceModel       from 'Models/time/PlanSourceModel';
import { urn }               from 'helpers/ModelDictionary';
import { Blamable }          from 'helpers/traits';
import { Timestampable }     from 'helpers/traits';
import TimePrivateApiModel   from '../../abstracts/TimePrivateApiModel';

@urn('$', 'time', 'plan_import')
export default class PlanImportModel extends Blamable(Timestampable(TimePrivateApiModel)) {
	public _sorts: ModelSortsExtended<{
		'id': string;
		'createdAt': string;
		'updatedAt': string;
	}> = {};

	static get path(): string {
		return '/plan_imports/{?id}';
	}

	public get label(): string {
		return this.get('label', '');
	}

	public get contentUrl(): string {
		return this.get('contentUrl', '');
	}

	public get file(): string {
		return this.get('file', '');
	}

	public get fileMimeType(): string {
		return this.get('fileMimeType', '');
	}

	public get fileOriginalName(): string {
		return this.get('fileOriginalName', '');
	}

	public get filePath(): string {
		return this.get('filePath', '');
	}

	public get fileSize(): number {
		return this.get('fileSize', 0);
	}

	public get folder(): string {
		return this.get('folder', '');
	}

	public get nbDeletions(): number {
		return this.get('nbDeletions', 0);
	}

	public get nbInsertions(): number {
		return this.get('nbInsertions', 0);
	}

	public get nbTotal(): number {
		return this.get('nbTotal', 0);
	}

	@resolvable(PlanImportStatusModel, { attributeName: 'planImportStatus', cache: true })
	public declare planImportStatus: PlanImportStatusModel;

	@resolvable(PlanSourceModel, { attributeName: 'planSource' })
	public declare planSource: PlanSourceModel;
}