/* eslint-disable @typescript-eslint/no-explicit-any */

import { Model }        from '@mathquis/modelx/';
import { action }       from 'mobx';
import { observable }   from 'mobx';
import { blobToBase64 } from 'tools/blobTools';

type Constructor = new (...args: any[]) => Model;

function withBlob<TBase extends Constructor>(Base: TBase) {
	return class extends Base {
		public _base64 = observable.box('');

		private _lockBLobToBase64 = false;

		constructor(...args) {
			super(args);
			this.setBase64 = action(this.setBase64); // Transformer la méthode en action
		}

		public get blob(): Blob {
			this._lockBLobToBase64 = false;

			return this.get('blob');
		}

		public get base64(): string | ArrayBuffer | null {
			if (!this._lockBLobToBase64 && this.blob && !this._base64.get()) {
				this._lockBLobToBase64 = true;

				blobToBase64(this.blob, base64 => this.setBase64(base64));
			}

			return this._base64.get();
		}

		public get src(): string {
			return this.base64?.toString() || '';
		}

		public fetch(options?: Record<string, unknown>): Promise<this> {
			return super.fetch({ responseType: 'blob', ...options });
		}

		setBase64(base64: string | ArrayBuffer | null) {
			this._base64.set(typeof base64 === 'string' ? base64 : '');
			this._lockBLobToBase64 = false;
		}

		protected transform(blob: Blob): Record<string, unknown> {
			return { blob };
		}
	};
}

export default withBlob;