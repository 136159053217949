import { isExpired }                      from 'Connectors/LoggedApiConnector';
import ObjectActionsModel                 from 'Models/rights/ObjectActionsModel';
import { makeObservable }                 from 'mobx';
import { action }                         from 'mobx';
import { computed }                       from 'mobx';
import { observable }                     from 'mobx';
import SessionModel                       from 'modelx/models/SessionModel';
import { analyticsLoggedApiConnector }    from 'modelx/models/abstracts/AnalyticsPrivateApiModel';
import { directoryLoggedApiConnector }    from 'modelx/models/abstracts/DirectoryPrivateApiModel';
import { graphLoggedApiConnector }        from 'modelx/models/abstracts/GraphPrivateApiModel';
import { interventionLoggedApiConnector } from 'modelx/models/abstracts/InterventionPrivateApiModel';
import { invoiceLoggedApiConnector }      from 'modelx/models/abstracts/InvoicePrivateApiModel';
import { partitionLoggedApiConnector }    from 'modelx/models/abstracts/PartitionPrivateApiModel';
import { rhLoggedConnector }              from 'modelx/models/abstracts/RhPrivateApiModel';
import { rightConnector }                 from 'modelx/models/abstracts/RightsPrivateApiModel';
import { salesLoggedConnector }           from 'modelx/models/abstracts/SalesPrivateApiModel';
import { timeLoggedApiConnector }         from 'modelx/models/abstracts/TimePrivateApiModel';
import { vehicleLoggedApiConnector }      from 'modelx/models/abstracts/VehiclePrivateApiModel';
import LoginCheckModel                    from 'modelx/models/public/rh/LoginCheckModel';
import TokenRefreshModel                  from 'modelx/models/public/rh/TokenRefreshModel';
import AbstractModelXStore                from 'stores/AbstractModelXStore';
import ConfigProxy                        from 'tools/ConfigProxy';
import { whenAsync }                      from 'tools/modelxTools';
import { notificationError }              from 'tools/notification';

const loggedConnectors = [
	analyticsLoggedApiConnector,
	directoryLoggedApiConnector,
	graphLoggedApiConnector,
	interventionLoggedApiConnector,
	invoiceLoggedApiConnector,
	partitionLoggedApiConnector,
	rhLoggedConnector,
	rightConnector,
	salesLoggedConnector,
	timeLoggedApiConnector,
	vehicleLoggedApiConnector,
];

export default class AuthenticationStore extends AbstractModelXStore {
	public objectAction = new ObjectActionsModel();
	public session = new SessionModel();

	@observable
	private _isAuthenticated = false;

	@observable
	private _isReady = false;

	@observable
	private _lockRefreshToken = false;

	constructor() {
		super();

		loggedConnectors.forEach(connector => connector.on401(this.logout));

		makeObservable(this);
	}

	public clear() {
		this.session.destroy().then(() => this.session.clear());
		this.objectAction.clear();

		return super.clear();
	}

	public login(username: string, password: string) {
		const loginCheckModel = new LoginCheckModel();

		return loginCheckModel
			.set({
				partitionUrn: ConfigProxy.get('PARTITION_URN'),
				password,
				username,
			})
			.save()
			.then(async () => {
				this.session.set({
					refreshToken: loginCheckModel.refreshToken,
					token: loginCheckModel.token,
				});

				await this.session.save();

				await this.onLoginSuccess();
			});
	}

	public logout = () => this.setIsAuthenticated(false);

	public async onLoginSuccess() {
		loggedConnectors.forEach(connector => {
			connector
				.setToken(this.session.token)
				.setExpiration(this.session.exp)
				.onExpired(this.refreshTokenAsync);
		});

		this.objectAction.clear();
		await this.objectAction.fetch();

		if (this.objectAction.hasAction('login_to_back_office')) {
			this.setIsAuthenticated(true);
		} else {
			notificationError(`Vous n’êtes pas autorisé à accéder à cette page`);
			this.logout();
			this.clear();
		}
	}

	public refreshTokenAsync = async () => {
		if (this._lockRefreshToken) {
			await whenAsync(() => !this._lockRefreshToken);
		} else {
			this._lockRefreshToken = true;
		}

		if (!isExpired(this.session.exp)) {
			console.log(`Token is not expired - skip refreshToken`);
			return;
		}

		console.log(`Token is expired - refreshToken`);

		try {
			const refreshModel = new TokenRefreshModel();

			if (this.session.refreshToken) {
				await refreshModel.patch({ refresh_token: this.session.refreshToken });

				this.session.set({
					refreshToken: refreshModel.refreshToken,
					token: refreshModel.token,
				});

				// Sauvegarde en localstorage
				await this.session.save();

				await this.onLoginSuccess();
			} else {
				this.logout();
			}
		} finally {
			this._lockRefreshToken = false;
		}
	};

	@computed
	public get isReady() {
		return this._isReady;
	}

	@action
	public setIsAuthenticated(value: boolean) {
		this._isAuthenticated = value;
	}

	@action
	public setIsReady(value: boolean) {
		this._isReady = value;
	}

	@computed
	public get isAuthenticated() {
		return this._isAuthenticated;
	}
}
