import model                    from 'decorators/model';
import { urn }                  from 'helpers/ModelDictionary';
import { Timestampable }        from 'helpers/traits';
import { Blamable }             from 'helpers/traits';
import { computed }             from 'mobx';
import moment                   from 'moment';
import DirectoryPrivateApiModel from '../../abstracts/DirectoryPrivateApiModel';

export type ContactGenderReference = 'MALE' | 'FEMALE';

@model.staticLabel('Contact')
@urn('$', 'directory', 'contact')
export default class ContactModel extends Blamable(Timestampable(DirectoryPrivateApiModel)) {

	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'email': string;
		'enabled': boolean;
		'firstName': string;
		'generalClients.clients.enabled': boolean;
		'generalClients.clients.partitionUrn': string;
		'lastName': string;
		'phoneNumber': string;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'email': string;
		'firstName': string;
		'gender': string;
		'id': string;
		'lastName': string;
		'phoneNumber': string;
		'phoneNumberBis': string;
	}> = {};

	static get path(): string {
		return '/contacts/{?id}';
	}

	@computed
	public get birthdate(): Moment | undefined {
		return this.get('birthdate') ? moment(this.get('birthdate')) : undefined;
	}

	public get birthplace(): string {
		return this.get('birthplace', '');
	}

	public get email(): string {
		return this.get('email', '');
	}

	public get enabled(): boolean {
		return this.get('enabled', false);
	}

	public get mobileNumber(): string {
		return this.get('mobileNumber', '');
	}

	public get firstName(): string {
		return this.get('firstName', '');
	}

	public get gender(): ContactGenderReference {
		return this.get('gender', '');
	}

	public get idNumber(): string {
		return this.get('idNumber', '');
	}

	public get lastName(): string {
		return this.get('lastName', '');
	}

	public get note(): string {
		return this.get('note', '');
	}

	public get phoneNumber(): string {
		return this.get('phoneNumber', '');
	}

	public get phoneNumberBis(): string {
		return this.get('phoneNumberBis', '');
	}

	public get socialSecurityNumber(): string {
		return this.get('socialSecurityNumber', '');
	}

	@computed
	public get fullName() {
		return [this.lastName.toUpperCase(), this.firstName].filter(s => s).join(' ');
	}

	@computed
	public get civilityLabel(): string {
		switch (this.gender) {
			case 'MALE':
				return 'M.';

			case 'FEMALE':
				return 'Mme.';
		}

		return '<unknown>';
	}

	@computed
	public get genderName(): string {
		switch (this.gender) {
			case 'MALE':
				return 'Homme';

			case 'FEMALE':
				return 'Femme';
		}

		return '<unknown>';
	}
}