import { LoggedApiConnector } from 'Connectors/LoggedApiConnector';
import ConfigProxy            from 'tools/ConfigProxy';
import AnalyticsApiModel      from './AnalyticsApiModel';

export const analyticsLoggedApiConnector = new LoggedApiConnector({
	baseURL: ConfigProxy.getServiceConfig('analytics', 'api_endpoint'),
});

export default class AnalyticsPrivateApiModel extends AnalyticsApiModel {
	public static get connector() {
		return analyticsLoggedApiConnector;
	}
}
